
.question {
  &__wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0.75rem;
    transition: all 300ms ease-in-out;

    .fa-disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
    .arrow {
      float: right;
    }
  }

  &__text {
    flex-basis: auto;
  }
  &__answer {
    cursor: pointer;
    flex-basis: auto;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 8px;
    padding: 4px;
  }
  &__right {
    min-width: 248px;
  }
}

.answer-comments {
  max-height: 0;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  margin: 0;
  padding: 0 0.75rem;

  textarea {
    width: 100%;
  }
}
