
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.review-selection-confirmation {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.intro-text {
  padding: 2rem;
  p {
    font-weight: 600;
  }
}
