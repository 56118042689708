
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.plan-container__summary {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.subtitle {
  margin-bottom: 0.4rem;
}

.grading-result {
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 600;
  line-height: 2rem;
}

.disclaimer {
  padding-left: $padding1x;
  padding-top: $padding1x;
  font-weight: 500;
}

.colour-code {
  height: 30px;
  width: 30px;
  border-radius: 15px;
}

p.link a {
  padding-bottom: 1rem;
}

.is-failed {
  background: "#ec685d";
}

.action-form {
  width: 100%;
  background-color: #ebeeed;
  display: flex;
  justify-content: space-between  ;
  
}
.action-form input {
 width: 98%;
}

.plus{
  border: 2px solid black;
 border-radius: 50%;
  padding: 5px;
  margin: 5px;
}
.actions-list{
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.actions-list-item{
  display: flex;
  background-color: #ebeeed;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0;
  

}
.trash{
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.trash:hover{
  color: red;
  
}


