
ul.question-list {
  margin: 0;
  padding: 0;
  border-radius: 1rem;
  border: 1px solid #eeedec;
}
ul.question-list > div:nth-child(even) {
  background: #eeedec;
}
