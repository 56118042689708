
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.review-template-container {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.title {
  .section-title {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .section-subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.4rem;
    &--bold {
      font-weight: 600;
    }
  }
}

.textarea {
  border-radius: $content-border-radius;
  border: $content-border;
  height: 100%;
  font-size: 1rem;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 10px;
  width: 100%;
  margin-top: 15px;
}
textarea,
textarea::placeholder {
  font-family: AllRoundGothicBook;
}

.grades-row {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 2rem;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.evaluation {
  margin-top: 1rem;
  .subtitle {
    &__bold {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
  }
}
